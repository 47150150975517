.Button {
  margin: 7px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 7px 30px;
  border: 0;
  position: relative;

  &.Button--Activated {
    cursor: not-allowed;
    &::after {
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(58, 184, 93, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    border: #68c195;
  }

  &.Button--Details {
    background: none;
    border: 0;
    padding: 0;
    margin-left: 10px;
  }

  &.Button--Primary {
    background: #3ab85d;
    padding: 12px 60px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
  }

  &.ButtonReset {
    background: #b4b4b4;
    font-size: 10px;
    line-height: 14px;
    font-weight: bold;
    color: #fff;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #707070;
    border-radius: 5px;
    span {
      flex: 1;
      font-size: 24px;
      line-height: 33px;
      color: #000000;
      font-weight: bold;
    }
  }
  &.fullWidth {
    width: 100%;
  }
}
