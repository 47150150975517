.RoomListItem {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background: #eaeaea;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  
  &:last-child {
    margin-bottom: 0;
  }

  &.RoomListItem--Default {
    .RoomListItemContent {
      flex: 1;
      display: flex;
      align-items: flex-end;
      padding: 10px;

      .RoomListItemTitle {
        font-size: 42px;
        //   line-height: 57px;
        line-height: 42px;
        font-weight: bold;
      }
      .RoomListItemSubTitle {
        font-size: 14px;
        line-height: 19px;
      }
    }
    .RoomListItemRoomCount {
      background: rgba(0,0,0, 0.2);
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .RoomListItemRoomCountIndicator {
        font-weight: bold;
        color: #fff;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  &.RoomListItem--Details {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  &.RoomListItem--Selected {
    background: #eaeaea linear-gradient(
      rgba(58, 184, 93, 0.62),
      rgba(58, 184, 93, 0.62)
    );
  }
}
