.RoomModal__RoomItem {
  max-width: 360px;
  background: #eaeaea;
  padding: 20px 32px 10px;
  border-radius: 5px;

  .RoomModal__RoomItem__Image {
    margin-bottom: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .RoomModal__RoomItem__Content {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .RoomModal__RoomItem__Content__Title {
      flex: 1;
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
    }
  }
}
