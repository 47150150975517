.HomeContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  height: 100%;

  // First step
  .property-type {
    display: flex;
    width: 100%;
    height: 100%;

    .card-radio-container {
      display: flex;
      width: 100%;
      height: 100%;

      .card-radio-container-item {
        flex: 1 1;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        font-size: 22px;
        color: #fff;
        text-align: center;
        font-weight: bold;
        width: 50%;
        padding-top: 20px;
        margin: 0 10px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        &.home {
          background: linear-gradient(
              rgba(128, 128, 128, 0.5),
              rgba(128, 128, 128, 0.5)
            ),
            url('./assets/home.png');
          &.checked {
            background: linear-gradient(
                rgba(58, 184, 93, 0.62),
                rgba(58, 184, 93, 0.62)
              ),
              url('./assets/home.png');
          }
        }
        &.apartment {
          background: linear-gradient(
              rgba(128, 128, 128, 0.5),
              rgba(128, 128, 128, 0.5)
            ),
            url('./assets/apartment.png');
          &.checked {
            background: linear-gradient(
                rgba(58, 184, 93, 0.62),
                rgba(58, 184, 93, 0.62)
              ),
              url('./assets/apartment.png');
          }
        }

        &.checked {
          &:before {
            background: #3ab85d;
          }
        }

        input {
          display: none;
        }
      }
    }
  }

  // Third step
  .card-checkbox-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-self: center;

    .card-checkbox-container-item {
      flex: 1 1;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      font-size: 22px;
      margin-bottom: 25px;
      color: #fff;
      text-align: center;
      font-weight: bold;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      border-radius: 5px;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
      &.shutter {
        background: linear-gradient(
            rgba(128, 128, 128, 0.5),
            rgba(128, 128, 128, 0.5)
          ),
          url('./assets/device-types/shutter.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/shutter.png');
        }
      }
      &.lightning {
        background: linear-gradient(
            rgba(128, 128, 128, 0.5),
            rgba(128, 128, 128, 0.5)
          ),
          url('./assets/device-types/lightning.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/lightning.png');
        }
      }
      &.heating {
        background: linear-gradient(
            rgba(128, 128, 128, 0.5),
            rgba(128, 128, 128, 0.5)
          ),
          url('./assets/device-types/heating.png');
        &.checked {
          background: linear-gradient(
              rgba(58, 184, 93, 0.62),
              rgba(58, 184, 93, 0.62)
            ),
            url('./assets/device-types/heating.png');
        }
      }
    }
  }
}
