.card-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .card-controls {
    display: flex;
    flex-direction: column;

    button.card-controls-reset {
      appearance: none;
      background: #B4B4B4;
      border-radius: 50px;
      border: 0;
      color: #fff;
      width: 84px;
      height: 26px;
      text-align: center;
      margin: 0 auto 20px;
      cursor: pointer;
    }

    div {
      font-size: 13px;
      font-weight: 700;
      text-align: center;
    }

    button.withIcon span {
      font-size: 20px;
    }
  }

}