.CustomLayoutSelection_Room {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 28px;
    padding-left: 16px;
    background: #eaeaea;
    border-radius: 5px;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    .CustomLayoutSelection_Room_Label {
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      flex: 1;
    }

    .CustomLayoutSelection_Room_Count {
      background: #b4b4b4;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 53px;
      height: 41px;
      border-radius: 5px;
      font-weight: bold;
    }
  }