.CustomOption {
  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    &:after {
      content: '';
      pointer-events: none;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        rgba(58, 184, 93, 0.62),
        rgba(58, 184, 93, 0.62)
      );
    }
  }

  &-children {
    position: relative;
    z-index: 2;
  }
}
