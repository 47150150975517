
.StepContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: calc(100% - 260px);
  max-width: 1600px;
  margin: 0 auto;

  &-action {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      appearance: none;
      height: 60px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-self: center;
      border: 2px solid #000;
      background: transparent;

      &:disabled {
        opacity: 0.2;
      }

      &.success {
        border-color: #3AB85D;
      }
    }
  }

  &-next {
    width: 80px;    
  }

  &-prev {
    width: 80px;
  }

  &-content {
    flex: 1;
  }
}