.QuizContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;

  & > div {
    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  .Container {
    flex: 1;
    display: flex;
  }
}
