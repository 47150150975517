$gray: #707070;
.TileSelect {
    width: 100%;
    margin-top: 50px;
    
  .TileSelect__Option {
    font-size: 20px;
    line-height: 33px;
    font-weight: bold;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    width: 100%;
    padding: 35px 10px;
    width: 100%;
    text-align: center;
    // border: 2px solid $gray;
    cursor: pointer;
    margin-bottom: 30px;

    &--Active {
      color: #fff;
      background: #3ab85d;
      border-color: #3ab85d;
    }
  }
}