$gray: #707070;
.Question {
    width: 100%;
    
  .Question__Option {
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: $gray;
    width: 100%;
    border-radius: 40px;
    padding: 15px 0;
    text-align: center;
    border: 2px solid $gray;
    cursor: pointer;
    margin-bottom: 30px;

    &--Active {
      color: #fff;
      background: #3ab85d;
      border-color: #3ab85d;
    }
  }
}
