.RecommendedProduct {
  width: 200px;
  margin-right: 70px;
  display: block;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
  .RecommendedProduct__Image {
    width: 110px;
    height: 110px;
    margin: 0 auto;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .RecommendedProduct__Title {
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .RecommendedProduct__Reference {
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 15px;
    color: #707070;
    font-weight: normal;
  }

  .RecommendedProduct__Button {
    button {
      background: #3ab85d;
      cursor: pointer;
      font-size: 10px;
      line-height: 14px;
      color: #fff;
      font-weight: bold;
      padding: 7px 30px;
      border-radius: 10px;
      border:0;
    }
  }
}
