.Card {
  flex: 1;
  max-width: 30%;
  border-radius: 30px;
  background: #f5f5f5;
  border: 1px solid #707070;
  padding: 20px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  display: flex;
  flex-direction: column;

  &-title {
    line-height: 41px;
    font-size: 30px;
    min-height: 82px;
    margin: 0;
  }

  &-subtitle {
    line-height: 14px;
    font-size: 10px;
    // min-height: 82px;
    color: #696969;
    margin: 0;
  }

  &-content {
    flex: 1;
    display: flex;
    overflow-y: auto;
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
