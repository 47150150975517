.BOMContainer {
  border-radius: 30px;
  background: #f5f5f5;
  border: 1px solid #707070;
  padding: 30px 50px;
  margin: 30px;

  .title {
    font-size: 30px;
    line-height: 43px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .RecommendedProducts {
    display: flex;
    background: #fff;
    padding: 10px;
  }

  .Selection {
    display: flex;
    overflow-x: auto;
    width: 100%;

    .Card-content {
      flex-direction: column;

      .BOM__Product {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        .BOM__Product__Image {
          width: 140px;
          height: 140px;
          margin:0 auto;

          img {
            width: 100%;
          }
        }

        .BOM__Product__Title {
          font-size: 18px;
          line-height: 24px;
          color:#707070;
          margin-bottom: 10px;
        }

        .BOM__Product__Reference {
          font-size: 11px;
          line-height: 15px;
          color:#707070;
          margin-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
}
