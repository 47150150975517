html {
  * {
    box-sizing: border-box;
    &:not(svg):not(text):not(tspan) {
      font-family: "Open Sans", sans-serif;
    }
  }

  height: 100%;
  body {
    height: 100%;
    #root {
      height: 100%;
      .App {
        height: 100%;
      }
    }
  }
}
