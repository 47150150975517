.AppHeader {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #EDEDED;

  .logo {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: 200px;
    }
  }

  .right {
    width: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    select {
      margin-right: 10px;
    }
  }
}
